import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StockRuleset } from 'store/StockRuleset'

export class StockRule extends Model {
  static backendResourceName = 'stock_rule'

  @observable id = null
  @observable name = ''
  @observable positiveMessage = ''
  @observable negativeMessage = ''
  @observable rule = ''
  @observable order = null
  @observable icon = ''

  relations() {
    return {
      ruleset: StockRuleset
    }
  }
}

export class StockRuleStore extends Store {
  static backendResourceName = 'stock_rule'
  Model = StockRule
}
